import React from 'react';
import './App.css';
import appIcon from './appicon.png'; // Adjust the path as necessary

function App() {
  return (
    <div className="app">
      <div>
        
      </div>
      <img src={appIcon} alt="App Icon" style={{ width: 125, height: 125, marginTop: 40 }} />
      <p>fiveRoll</p>
      <header>
        <h1>FAQs</h1>
      </header>
      <section>
        <h3>1. How do viewer's lists work?</h3>
        <p>It's important that you have full control over who sees your fiveRolls, so only people you add to your viewer's list can see them. People will have to accept your request for you to finish adding them to your list.</p>
        <h3>2. How do I accept pending viewer requests?</h3>
        <p>Head over to notifications (top right icon on your feed).</p>
        <h3>3. I can't see my fiveRoll (it's blank) and/or my comments.</h3>
        <p>It's likely that one or more of your images or comments were in violation of our content policy and were automatically removed. Contact us at help@fiveroll.io if you think it was a mistake.</p>
        <h3>4. How do I delete my account?</h3>
        <p>Head to your profile → settings icon → Delete Account. Account deletions may take up to 3 business days to complete.</p>
        {/* Your FAQ content */}
      </section>
      <hr class="divider" />
      <header>
        <h1>Help & Support</h1>
      </header>
      <section>
        <p>fiveRoll is still in alpha stage! We're still taking our baby steps, so please bear with us as we try to give you the best experience possible 🥹</p>
        <p>Please don't hesitate to contact us if you have any questions:</p>
        <p>General requests, suggestions, and bug reports can be done inside the app. Head to your profile → settings icon → Report A Bug / Suggestion.</p>
        <p>For urgent inquiries, please contact us at</p>
        <h3>help@fiveroll.io</h3>
      </section>
      <hr class="divider" />
      <div style={{marginBottom: 40}}>
        <a href="https://spurious-hornet-437.notion.site/Terms-of-Service-edaaf3052d5d41ab8d7ba12c6dccd23b?pvs=74" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <a>&nbsp;·&nbsp;</a>
        <a href="https://spurious-hornet-437.notion.site/Privacy-Policy-7bc6772f982441c8a55242bff46472bd" target="_blank" rel="noopener noreferrer">
        Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default App;
